import { Dropdown, IDropdownOption, Stack, StackItem, TextField } from "@fluentui/react";
import { AttachmentComponent, IFileInfo } from "ccs-azure";
import { AppTopContext } from "components/Welcome/Common/AppTopContext";
import DataProvider from "dataprovider/DataProvider";
import { IAppFileInfo, INavigationActions, IReturnAction, IUserBasicData, IUserFieldsState } from "Interfaces/IHRBrazil";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppConstants } from "../../../../Utility/Constants";
import { ActionSection } from "./ActionSection";
import "./UserFormCommon.scss";
import "./BasicForm.scss";
import { Utility } from "components/Utility/Utility";
import { AppCalendar } from "components/Welcome/Common/AppCalendar";
import { BasicFormTXTEnum, SaveTypeEnum, ValidationType } from "components/Utility/AppEnums";
import { FileSizeMSG } from "components/Welcome/Common/FileSizeMSG";
export interface IBasicFormProps {
  user: IUserFieldsState;
  isFormEditable: boolean;
  previousCall: any;
  nextCall: any;
  submitCall: any;
  userProfileFormData: IUserBasicData;
  navigationAction: INavigationActions;
  saveUserProfile: (sectionKey: string, data: any, updateData: boolean, callFunc: any) => void;
  updateFormMode: (formMode: boolean) => void;
}

export const BasicForm: React.FunctionComponent<IBasicFormProps> = ({ ...props }) => {
  const [_userProfileFormData, _setUserProfileFormData] = useState<IUserBasicData>(Utility.GetInitialData(AppConstants.SectionKey.BasicData));
  let [_isFileUpdated, _setIsFileUpdated] = useState<boolean>(false);
  let [_badgeDocs, _setBadgeDocs] = useState<Array<IFileInfo | File>>([]);
  let [_covidDocs, _setCovidDocs] = useState<Array<IFileInfo | File>>([]);
  var dataProvider = new DataProvider();
  const appContext = useContext(AppTopContext);
  const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);
  let [_genderOptions, _setGenderOptions] = useState<IDropdownOption[]>([]);
  let [_deletedDocs, _setDeletedDocs] = useState<Array<IFileInfo>>([]);

  useEffect(() => {
    initializeForm();
  }, [props.userProfileFormData]);

  /// update dll according to localize
  useEffect(() => {
    var options = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.Gender);
    _setGenderOptions(options);
  }, [appContext.IsLBrazil, (props as any).currentUser?.AppChoices]);

  useEffect(() => {
    if (props.navigationAction.Guid) {
      if (props.isFormEditable) {
        let isFormUpdated = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.BasicData, props.user, _userProfileFormData);
        if (appContext.IsUserAdmin) {
          if (isFormUpdated) {
            appContext.ShowAppModal(t("Information.AdminPanelAction.Header"), t("Information.AdminPanelAction.MSG"));
          } else {
            props.navigationAction.NavigationFun();
          }
        } else {
          callPropSaveDataCallBackFunction(isFormUpdated, props.navigationAction.NavigationFun);
        }
      } else {
        props.navigationAction.NavigationFun();
      }
    }
  }, [props.navigationAction.Guid]);

  function initializeForm() {
    let initialData = Utility.GetInitialData(AppConstants.SectionKey.BasicData, props.userProfileFormData) as IUserBasicData;
    _setBadgeDocs(Utility.GetFiles(initialData.BadgePhoto));
    _setCovidDocs(Utility.GetFiles(initialData.CovidVacine));
    _setUserProfileFormData(initialData);
    _setDeletedDocs([]);
  }
  function resetForm() {
    /// check for change and reset
    let isFormDataUpdated = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.BasicData, props.user, _userProfileFormData);
    if (isFormDataUpdated) {
      appContext.ShowAppConfirmationModal(
        t("ActionBtn.ExitEditModeConfirmation.Header"),
        t("ActionBtn.ExitEditModeConfirmation.MSG"),
        t("ActionBtn.ExitEditModeConfirmation.OKBtnTxt"),
        t("ActionBtn.ExitEditModeConfirmation.CancelBtnTxt"),
        function () {
          /// call reset data
          initializeForm();
        }
      );
    } else {
      props.updateFormMode(false);
    }
  }

  function callPropSaveDataCallBackFunction(isDataUpdateRequire: boolean, func: any) {
    appContext.ShowAppLoader(true, "");
    if (isDataUpdateRequire) {
      if (appContext.IsUserAdmin) {
        /// validate page data
        if (!Utility.IsBasicFormValidated(_userProfileFormData, ValidationType.FieldOnly)) {
          appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.RequiredFieldValidation"));
          appContext.ShowAppLoader(false, "");
          return;
        }
        if (!Utility.IsRequiredFormDocsPresent([_badgeDocs, _covidDocs])) {
          appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.RequiredFieldValidation"));
          appContext.ShowAppLoader(false, "");
          return;
        }
      }
      if (!Utility.IsFilesSizeValid([_badgeDocs, _covidDocs])) {
        appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.FileSizeLimit"));
        appContext.ShowAppLoader(false, "");
        return;
      }
      dataProvider
        .deleteUserDocs(props.user.UserId, _deletedDocs)
        .then((delResponse: any) => {
          if (delResponse?.hasError) {
            console.log(delResponse);
            if (!Utility.IsUnauthorizeError(delResponse.errorDetail, appContext)) {
              appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.DocumentDeleteError"));
            }
            appContext.ShowAppLoader(false, "");
          } else {
            Promise.all([
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.BadgePhotoDocs.Display, _badgeDocs),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.CovidCertificateDocs.Display, _covidDocs),
            ])
              .then((res) => {
                let errorMSG = false;
                if (!res[0]?.hasError) {
                  _userProfileFormData.BadgePhoto = res[0].data as IAppFileInfo[];
                  if (_userProfileFormData.BadgePhoto.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.BadgePhoto = [_userProfileFormData.BadgePhoto[0]];
                  }
                } else {
                  console.log(res[0]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[1]?.hasError) {
                  _userProfileFormData.CovidVacine = res[1].data as IAppFileInfo[];
                  if (_userProfileFormData.CovidVacine.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.CovidVacine = [_userProfileFormData.CovidVacine[0]];
                  }
                } else {
                  console.log(res[1]?.ErrorDetail);
                  errorMSG = true;
                }
                if (errorMSG) {
                  appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.DocumentUploadError"));
                  appContext.ShowAppLoader(false, "");
                } else {
                  props.saveUserProfile(AppConstants.SectionKey.BasicData, _userProfileFormData, isDataUpdateRequire, func);
                }
              })
              .catch((err) => {
                appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.GenericError"));
                console.log(err);
                appContext.ShowAppLoader(false, "");
              });
          }
        })
        .catch((err) => {
          appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.GenericError"));
          console.log(err);
          appContext.ShowAppLoader(false, "");
        });
    } else {
      func();
    }
  }
  const onFileDeleted =
    (attachmentType: string) =>
    (file: IFileInfo | File): Promise<void> => {
      let attachments = [];
      let deletedAttachments = [];
      let idx = -1;
      switch (attachmentType) {
        case AppConstants.AttachmentType.BadgePhotoDocs.Type:
          attachments = [..._badgeDocs];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setBadgeDocs(attachments);
          }
          break;
        case AppConstants.AttachmentType.CovidCertificateDocs.Type:
          attachments = [..._covidDocs];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setCovidDocs(attachments);
          }
          break;
        default:
          break;
      }
      if (file instanceof File) {
      } else {
        deletedAttachments = [..._deletedDocs];
        deletedAttachments.push(file);
        _setDeletedDocs(deletedAttachments);
      }
      _setIsFileUpdated(true);
      return Promise.resolve(null);
    };
  const onFileDownload = (file: IFileInfo): Promise<void> => {
    appContext.ShowAppLoader(true, "");
    dataProvider
      .downloadUserDoc(props.user.UserId, file.fileId, file.fileName)
      .then((res) => {
        let r = res;
        appContext.ShowAppLoader(false, "");
      })
      .catch((err) => {
        console.log(err);
        appContext.ShowAppLoader(false, "");
        appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.FileDownloadError"));
      });
    return Promise.resolve(null);
  };
  const onFilesAdded =
    (attachmentType: string) =>
    (files: File[]): Promise<void> => {
      let attachments = [];
      switch (attachmentType) {
        case AppConstants.AttachmentType.BadgePhotoDocs.Type:
          attachments = [..._badgeDocs];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setBadgeDocs(attachments);
          break;
        case AppConstants.AttachmentType.CovidCertificateDocs.Type:
          attachments = [..._covidDocs];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setCovidDocs(attachments);
          break;
        default:
          break;
      }
      _setIsFileUpdated(true);
      return Promise.resolve();
    };
  function _onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string, fieldType: BasicFormTXTEnum) {
    let userOptions = JSON.parse(JSON.stringify(_userProfileFormData)) as IUserBasicData;
    switch (fieldType) {
      case BasicFormTXTEnum.FatherName:
        userOptions.FatherName = text?.toUpperCase();
        break;
      case BasicFormTXTEnum.FullName:
        userOptions.FullName = text?.toUpperCase();
        break;
      case BasicFormTXTEnum.MotherName:
        userOptions.MotherName = text?.toUpperCase();
        break;
      default:
        break;
    }
    _setUserProfileFormData(userOptions);
  }
  function _onGenderChange(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) {
    _setUserProfileFormData({
      ..._userProfileFormData,
      Gender: (item.key as string)?.toUpperCase(),
    });
  }

  function actionCallbackFun(saveType: SaveTypeEnum) {
    let actionProps: IReturnAction = Utility.GetActionProps(saveType, props.previousCall, props.nextCall);
    if (actionProps.saveRequired) {
      actionProps.saveRequired = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.BasicData, props.user, _userProfileFormData);
    }
    callPropSaveDataCallBackFunction(actionProps.saveRequired, actionProps.callBack);
  }
  return (
    <Stack>
      <Stack className="formHeader">{t("UserBasicData.FormHeader")}</Stack>
      <ActionSection
        resetForm={resetForm}
        isFormEditable={props.isFormEditable}
        {...props}
        previousCallFun={undefined}
        submitCallFun={undefined}
        nextCallFun={actionCallbackFun}
      />
      <Stack className="formSection formDetails" horizontal>
        <StackItem className="formSubSection">
          <Stack>
            <StackItem className="fields">
              <Stack className="field">
                <StackItem className="label">
                  {t("UserBasicData.FormFields.FullName")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value">
                  <TextField
                    disabled={!props.isFormEditable}
                    autoComplete="off"
                                      value={_userProfileFormData.FullName?.toUpperCase()}
                    onChange={(ev, text) => {
                      _onChangeText(ev, text, BasicFormTXTEnum.FullName);
                    }}
                  ></TextField>
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserBasicData.FormFields.Gender")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value">
                  <Dropdown
                    disabled={!props.isFormEditable}
                    calloutProps={{ directionalHintFixed: true }}
                    options={_genderOptions}
                    onChange={_onGenderChange}
                    selectedKey={_userProfileFormData.Gender?.toUpperCase()}
                  />
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserBasicData.FormFields.BirthDate")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value">
                  <AppCalendar
                    IsEditable={props.isFormEditable}
                    InitialDate={_userProfileFormData.BirthDate ? new Date(_userProfileFormData.BirthDate) : null}
                    GetCalendatDate={function (date: Date): void {
                      _userProfileFormData.BirthDate = date.toUTCString();
                      _setUserProfileFormData(_userProfileFormData);
                    }}
                  />
                </StackItem>
              </Stack>

              <Stack className="field">
                <StackItem className="label">
                  {t("UserBasicData.FormFields.MotherName")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value">
                  <TextField
                    disabled={!props.isFormEditable}
                    autoComplete="off"
                                      value={_userProfileFormData.MotherName?.toUpperCase()}
                    onChange={(ev, text) => {
                      _onChangeText(ev, text, BasicFormTXTEnum.MotherName);
                    }}
                  ></TextField>
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">{t("UserBasicData.FormFields.FatherName")}</StackItem>
                <StackItem className="value">
                  <TextField
                    disabled={!props.isFormEditable}
                    autoComplete="off"
                                      value={_userProfileFormData.FatherName?.toUpperCase()}
                    onChange={(ev, text) => {
                      _onChangeText(ev, text, BasicFormTXTEnum.FatherName);
                    }}
                  ></TextField>
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem className="formSubSection">
          <Stack>
            <StackItem className="fields">
              <Stack className="field">
                <StackItem className="label">
                  {t("UserBasicData.FormFields.BadgePhoto")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                  <AttachmentComponent
                    texts={Utility.getAttachmentTexts()}
                    fileMode="SingleFile"
                    attachment={_badgeDocs.length > 0 ? _badgeDocs[0] : null}
                    enableDropZone={true}
                    extensionFilters={AppConstants.fileExtensionAllowed}
                    onFileDeleted={onFileDeleted(AppConstants.AttachmentType.BadgePhotoDocs.Type)}
                    onFileDownload={onFileDownload}
                    onFilesAdded={onFilesAdded(AppConstants.AttachmentType.BadgePhotoDocs.Type)}
                    readonly={!props.isFormEditable}
                  ></AttachmentComponent>
                  <FileSizeMSG files={_badgeDocs} />
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserBasicData.FormFields.CovidVaccine")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                  <AttachmentComponent
                    texts={Utility.getAttachmentTexts()}
                    fileMode="SingleFile"
                    attachment={_covidDocs.length > 0 ? _covidDocs[0] : null}
                    enableDropZone={true}
                    extensionFilters={AppConstants.fileExtensionAllowed}
                    onFileDeleted={onFileDeleted(AppConstants.AttachmentType.CovidCertificateDocs.Type)}
                    onFileDownload={onFileDownload}
                    onFilesAdded={onFilesAdded(AppConstants.AttachmentType.CovidCertificateDocs.Type)}
                    readonly={!props.isFormEditable}
                  ></AttachmentComponent>
                  <FileSizeMSG files={_covidDocs} />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </Stack>
  );
};
