import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Icon,
  IDropdownOption,
  INavLink,
  INavLinkGroup,
  Nav,
  SpinnerType,
  Stack,
  TextField,
} from "@fluentui/react";
import { Label, Spinner, SpinnerSize, StackItem } from "@fluentui/react";
import { AttachmentComponent, IFileInfo } from "ccs-azure";
import { AdditionalFormDLLEnum, AdditionalFormTXTEnum, SaveTypeEnum, ValidationType } from "components/Utility/AppEnums";
import { Utility } from "components/Utility/Utility";
import { AppTopContext } from "components/Welcome/Common/AppTopContext";
import { FileSizeMSG } from "components/Welcome/Common/FileSizeMSG";
import DataProvider from "dataprovider/DataProvider";
import { IAppFileInfo, INavigationActions, IReturnAction, IUserAdditionalData, IUserAdditionalDataState, IUserFieldsState } from "Interfaces/IHRBrazil";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppConstants } from "../../../../Utility/Constants";
import { ActionSection } from "./ActionSection";
import "./Additional.scss";
export interface IAdditionalFormProps {
  user: IUserFieldsState;
  isFormEditable: boolean;
  previousCall: any;
  nextCall: any;
  submitCall: any;
  userProfileFormData: IUserAdditionalData;
  navigationAction: INavigationActions;
  saveUserProfile: (sectionKey: string, data: any, updateData: boolean, callFunc: any) => void;
  updateFormMode: (formMode: boolean) => void;
}

export const AdditionalForm: React.FunctionComponent<IAdditionalFormProps> = ({ ...props }) => {
  const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);
  const appContext = useContext(AppTopContext);
  let [_nationalityOptions, _setNationalityOptions] = useState<IDropdownOption[]>([]);
  let [_raceColorOptions, _setRaceColorOptions] = useState<IDropdownOption[]>([]);
  let [_birthCountryOptions, _setBirthCountryOptions] = useState<IDropdownOption[]>([]);
  let [_addressCountryOptions, _setAddressCountryOptions] = useState<IDropdownOption[]>([]);
  let [_maritalStatusOptions, _setMaritalStatusOptions] = useState<IDropdownOption[]>([]);
  let [_educationLevelOptions, _setEducationLevelOptions] = useState<IDropdownOption[]>([]);
  var dataProvider = new DataProvider();

  useEffect(() => {}, []);
  useEffect(() => {
    var nationalityOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.Nationality);
    _setNationalityOptions(nationalityOptions);
    var raceColorOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.RaceColor);
    _setRaceColorOptions(raceColorOptions);
    var countryOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.Country);
    _setBirthCountryOptions(countryOptions);
    _setAddressCountryOptions(countryOptions);
    var maritalStatusOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.MaritalStatus);
    _setMaritalStatusOptions(maritalStatusOptions);

    var educationLevelOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.EducationLevel);
    _setEducationLevelOptions(educationLevelOptions);
  }, [appContext.IsLBrazil]);
  let [_isFileUpdated, _setIsFileUpdated] = useState<boolean>(false);
  let [_deletedDocs, _setDeletedDocs] = useState<Array<IFileInfo>>([]);
  let [_educationDocs, _setEducationDocs] = useState<Array<IFileInfo | File>>([]);
  let [_otherCertificates, _setOtherCertificates] = useState<Array<IFileInfo | File>>([]);
  let [_maritalDocs, _setMaritalDocs] = useState<Array<IFileInfo | File>>([]);
  let [_addressDocs, _setAddressDocs] = useState<Array<IFileInfo | File>>([]);
  const [_userProfileFormData, _setUserProfileFormData] = useState<IUserAdditionalData>(Utility.GetInitialData(AppConstants.SectionKey.AdditionalData));
  useEffect(() => {
    if (props.navigationAction.Guid) {
      if (props.isFormEditable) {
        let isFormUpdated = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.AdditionalData, props.user, _userProfileFormData);
        if (appContext.IsUserAdmin) {
          if (isFormUpdated) {
            appContext.ShowAppModal(t("Information.AdminPanelAction.Header"), t("Information.AdminPanelAction.MSG"));
          } else {
            props.navigationAction.NavigationFun();
          }
        } else {
          callPropSaveDataCallBackFunction(isFormUpdated, props.navigationAction.NavigationFun);
        }
      } else {
        props.navigationAction.NavigationFun();
      }
    }
  }, [props.navigationAction.Guid]);
  useEffect(() => {
    initializeForm();
  }, [props.userProfileFormData]);

  function initializeForm() {
    let initialData = Utility.GetInitialData(AppConstants.SectionKey.AdditionalData, props.userProfileFormData) as IUserAdditionalData;
    _setEducationDocs(Utility.GetFiles(initialData.EducationCertificates));
    _setOtherCertificates(Utility.GetFiles(initialData.OtherCertificates));
    _setMaritalDocs(Utility.GetFiles(initialData?.MaritalCert));
    _setAddressDocs(Utility.GetFiles(initialData.ProofOfAddress));
    _setUserProfileFormData(initialData);

    _setDeletedDocs([]);
  }
  function resetForm() {
    /// check for change and reset
    let isFormDataUpdated = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.AdditionalData, props.user, _userProfileFormData);
    if (isFormDataUpdated) {
      appContext.ShowAppConfirmationModal(
        t("ActionBtn.ExitEditModeConfirmation.Header"),
        t("ActionBtn.ExitEditModeConfirmation.MSG"),
        t("ActionBtn.ExitEditModeConfirmation.OKBtnTxt"),
        t("ActionBtn.ExitEditModeConfirmation.CancelBtnTxt"),
        function () {
          /// call reset data
          initializeForm();
        }
      );
    } else {
      props.updateFormMode(false);
    }
  }
  function callPropSaveDataCallBackFunction(isDataUpdateRequire: boolean, func: any) {
    appContext.ShowAppLoader(true, "");
    if (isDataUpdateRequire) {
      if (appContext.IsUserAdmin) {
        /// validate page data
        if (!Utility.IsAdditionalFormValidated(_userProfileFormData, ValidationType.FieldOnly)) {
          appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.RequiredFieldValidation"));
          appContext.ShowAppLoader(false, "");
          return;
        }
        let requiredDocs = [_educationDocs, _maritalDocs, _addressDocs, _otherCertificates];
        if (_userProfileFormData?.MaritalStatus == AppConstants.maritalStatus.Single) {
          requiredDocs = [_educationDocs, _addressDocs, _otherCertificates];
        }

        if (!Utility.IsRequiredFormDocsPresent(requiredDocs)) {
          appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.RequiredFieldValidation"));
          appContext.ShowAppLoader(false, "");
          return;
        }
      }

      if (!Utility.IsFilesSizeValid([_educationDocs, _maritalDocs, _addressDocs, _otherCertificates])) {
        appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.FileSizeLimit"));
        appContext.ShowAppLoader(false, "");
        return;
      }

      dataProvider
        .deleteUserDocs(props.user.UserId, _deletedDocs)
        .then((delResponse: any) => {
          if (delResponse?.hasError) {
            console.log(delResponse);
            if (!Utility.IsUnauthorizeError(delResponse.errorDetail, appContext)) {
              appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.DocumentDeleteError"));
            }
            appContext.ShowAppLoader(false, "");
          } else {
            Promise.all([
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.EducationDocs.Display, _educationDocs),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.MaritalDocs.Display, _maritalDocs),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.AddressDocs.Display, _addressDocs),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.OtherCertificates.Display, _otherCertificates),
            ])
              .then((res) => {
                let errorMSG = false;
                if (!res[0]?.hasError) {
                  _userProfileFormData.EducationCertificates = res[0].data as IAppFileInfo[];
                } else {
                  console.log(res[0]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[1]?.hasError) {
                  _userProfileFormData.MaritalCert = res[1].data as IAppFileInfo[];
                } else {
                  console.log(res[1]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[2]?.hasError) {
                  _userProfileFormData.ProofOfAddress = res[2].data as IAppFileInfo[];
                  if (_userProfileFormData.ProofOfAddress.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.ProofOfAddress = [_userProfileFormData.ProofOfAddress[0]];
                  }
                } else {
                  console.log(res[2]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[3]?.hasError) {
                  _userProfileFormData.OtherCertificates = res[3].data as IAppFileInfo[];
                  if (_userProfileFormData.OtherCertificates.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.OtherCertificates = [_userProfileFormData.OtherCertificates[0]];
                  }
                } else {
                  console.log(res[3]?.ErrorDetail);
                  errorMSG = true;
                }
                if (errorMSG) {
                  appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.DocumentUploadError"));
                }
                props.saveUserProfile(AppConstants.SectionKey.AdditionalData, _userProfileFormData, isDataUpdateRequire, func);
              })
              .catch((err) => {
                appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.GenericError"));
                console.log(err);
                appContext.ShowAppLoader(false, "");
              });
          }
        })
        .catch((err) => {
          appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.GenericError"));
          console.log(err);
          appContext.ShowAppLoader(false, "");
        });
    } else {
      func();
    }
  }
  function actionCallbackFun(saveType: SaveTypeEnum) {
    let actionProps: IReturnAction = Utility.GetActionProps(saveType, props.previousCall, props.nextCall);
    if (actionProps.saveRequired) {
      actionProps.saveRequired = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.AdditionalData, props.user, _userProfileFormData);
    }
    callPropSaveDataCallBackFunction(actionProps.saveRequired, actionProps.callBack);
  }
  const onFileDeleted =
    (attachmentType: string) =>
    (file: IFileInfo | File): Promise<void> => {
      let attachments = [];
      let deletedAttachments = [];
      let idx = -1;
      switch (attachmentType) {
        case AppConstants.AttachmentType.EducationDocs.Type:
          attachments = [..._educationDocs];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setEducationDocs(attachments);
          }
          break;
        case AppConstants.AttachmentType.OtherCertificates.Type:
          attachments = [..._otherCertificates];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setOtherCertificates(attachments);
          }
          break;
        case AppConstants.AttachmentType.MaritalDocs.Type:
          attachments = [..._maritalDocs];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setMaritalDocs(attachments);
          }
          break;
        case AppConstants.AttachmentType.AddressDocs.Type:
          attachments = [..._addressDocs];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setAddressDocs(attachments);
          }
          break;

        default:
          break;
      }
      if (file instanceof File) {
      } else {
        deletedAttachments = [..._deletedDocs];
        deletedAttachments.push(file);
        _setDeletedDocs(deletedAttachments);
      }
      _setIsFileUpdated(true);
      return Promise.resolve(null);
    };
  const onFileDownload = (file: IFileInfo): Promise<void> => {
    appContext.ShowAppLoader(true, "");
    dataProvider
      .downloadUserDoc(props.user.UserId, file.fileId, file.fileName)
      .then((res) => {
        let r = res;
        appContext.ShowAppLoader(false, "");
      })
      .catch((err) => {
        console.log(err);
        appContext.ShowAppLoader(false, "");
        appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.FileDownloadError"));
      });
    return Promise.resolve(null);
  };
  const onFilesAdded =
    (attachmentType: string) =>
    (files: File[]): Promise<void> => {
      let attachments = [];
      switch (attachmentType) {
        case AppConstants.AttachmentType.EducationDocs.Type:
          attachments = [..._educationDocs];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setEducationDocs(attachments);
          break;
        case AppConstants.AttachmentType.OtherCertificates.Type:
          attachments = [..._otherCertificates];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setOtherCertificates(attachments);
          break;
        case AppConstants.AttachmentType.MaritalDocs.Type:
          attachments = [..._maritalDocs];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setMaritalDocs(attachments);
          break;
        case AppConstants.AttachmentType.AddressDocs.Type:
          attachments = [..._addressDocs];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setAddressDocs(attachments);
          break;
        default:
          break;
      }
      _setIsFileUpdated(true);
      return Promise.resolve();
    };

  function _onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string, fieldType: AdditionalFormTXTEnum) {
    let userProfileFormData = JSON.parse(JSON.stringify(_userProfileFormData)) as IUserAdditionalData;
    switch (fieldType) {
      case AdditionalFormTXTEnum.AAddress:
            userProfileFormData.AddressVerification.Address = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.ACEP:
            userProfileFormData.AddressVerification.CEP = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.ACity:
            userProfileFormData.AddressVerification.City = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.AComplement:
            userProfileFormData.AddressVerification.Complement = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.ADistrict:
            userProfileFormData.AddressVerification.District = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.ANumber:
            userProfileFormData.AddressVerification.Number = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.APublicArea:
            userProfileFormData.AddressVerification.PublicArea = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.AState:
            userProfileFormData.AddressVerification.State = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.BCity:
            userProfileFormData.Birthplace.City = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.BState:
            userProfileFormData.Birthplace.State = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.Email:
            userProfileFormData.Email = text?.toUpperCase();
        break;
      case AdditionalFormTXTEnum.Telephone:
            userProfileFormData.Telephone = text?.toUpperCase();
        break;
      default:
        break;
    }
    _setUserProfileFormData(userProfileFormData);
  }
  function _onDLLChange(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption, ddlOptionType: AdditionalFormDLLEnum) {
    let userProfileFormData = JSON.parse(JSON.stringify(_userProfileFormData)) as IUserAdditionalData;
    switch (ddlOptionType) {
      case AdditionalFormDLLEnum.ACountry:
            userProfileFormData.AddressVerification.Country  =(item.key as string)?.toUpperCase()
        break;
      case AdditionalFormDLLEnum.BCountry:
            userProfileFormData.Birthplace.Country = (item.key as string)?.toUpperCase()
        break;
      case AdditionalFormDLLEnum.Nationality:
            userProfileFormData.Nationality = (item.key as string)?.toUpperCase()
        break;
      case AdditionalFormDLLEnum.RaceColor:
            userProfileFormData.Race = (item.key as string)?.toUpperCase()
        break;
      case AdditionalFormDLLEnum.MaritalStatus:
            userProfileFormData.MaritalStatus = (item.key as string)?.toUpperCase()
        break;
      case AdditionalFormDLLEnum.EducationLevel:
            userProfileFormData.EducationLevel = (item.key as string)?.toUpperCase()
        break;
      default:
        break;
    }
    _setUserProfileFormData(userProfileFormData);
  }
  return (
    <>
      <Stack className="formHeader">{t("UserAdditionalData.FormHeader")}</Stack>
      <ActionSection
        resetForm={resetForm}
        {...props}
        isFormEditable={props.isFormEditable}
        previousCallFun={actionCallbackFun}
        nextCallFun={actionCallbackFun}
        submitCallFun={undefined}
      />
      <Stack className="formSection formAdditional" horizontal>
        <StackItem className="formSubSection">
          <Stack>
            <StackItem className="fields">
              <Stack className="field">
                <StackItem className="label">
                  {t("UserAdditionalData.FormFields.Nationality")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value">
                  <Dropdown
                    disabled={!props.isFormEditable}
                    calloutProps={{ directionalHintFixed: true }}
                    options={_nationalityOptions}
                    selectedKey={_userProfileFormData?.Nationality?.toUpperCase()}
                    onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                      _onDLLChange(event, item, AdditionalFormDLLEnum.Nationality);
                    }}
                  />
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserAdditionalData.FormFields.RaceColor")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value">
                  <Dropdown
                    disabled={!props.isFormEditable}
                    calloutProps={{ directionalHintFixed: true }}
                    options={_raceColorOptions}
                    selectedKey={_userProfileFormData?.Race?.toUpperCase()}
                    onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                      _onDLLChange(event, item, AdditionalFormDLLEnum.RaceColor);
                    }}
                  />
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserAdditionalData.FormFields.Telephone")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value">
                  <TextField
                    disabled={!props.isFormEditable}
                    autoComplete="off"
                    value={_userProfileFormData.Telephone}
                    onChange={(ev, text) => {
                      _onChangeText(ev, text, AdditionalFormTXTEnum.Telephone);
                    }}
                  ></TextField>
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserAdditionalData.FormFields.Email")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value">
                  <TextField
                    disabled={!props.isFormEditable}
                    autoComplete="off"
                                      value={_userProfileFormData.Email?.toUpperCase()}
                    onChange={(ev, text) => {
                      _onChangeText(ev, text, AdditionalFormTXTEnum.Email);
                    }}
                  ></TextField>
                </StackItem>
              </Stack>

              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserAdditionalData.FormFields.BirthplaceSectionName")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.BirthplaceSection.Country")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <Dropdown
                        disabled={!props.isFormEditable}
                        calloutProps={{ directionalHintFixed: true }}
                        options={_birthCountryOptions}
                                              selectedKey={_userProfileFormData?.Birthplace?.Country?.toUpperCase()}
                        onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                          _onDLLChange(event, item, AdditionalFormDLLEnum.BCountry);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.BirthplaceSection.State")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData?.Birthplace?.State?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, AdditionalFormTXTEnum.BState);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.BirthplaceSection.City")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData?.Birthplace?.City?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, AdditionalFormTXTEnum.BCity);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserAdditionalData.FormFields.EducationLevel")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value">
                  <Dropdown
                    disabled={!props.isFormEditable}
                    calloutProps={{ directionalHintFixed: true }}
                    options={_educationLevelOptions}
                    onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                        _onDLLChange(event, item, AdditionalFormDLLEnum.EducationLevel);
                    }}
                                      selectedKey={_userProfileFormData?.EducationLevel?.toUpperCase()}
                  />
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserAdditionalData.FormFields.EducationCertificate")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="label" style={{ overflowWrap: "anywhere" }}>
                  <AttachmentComponent
                    texts={Utility.getAttachmentTexts()}
                    fileMode="MultiFile"
                    enableDropZone={true}
                    extensionFilters={AppConstants.fileExtensionAllowed}
                    onFileDeleted={onFileDeleted(AppConstants.AttachmentType.EducationDocs.Type)}
                    onFileDownload={onFileDownload}
                    onFilesAdded={onFilesAdded(AppConstants.AttachmentType.EducationDocs.Type)}
                    readonly={!props.isFormEditable}
                    attachments={_educationDocs}
                  ></AttachmentComponent>
                  <FileSizeMSG files={_educationDocs} />
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserAdditionalData.FormFields.Certificate")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                  <AttachmentComponent
                    texts={Utility.getAttachmentTexts()}
                    fileMode="MultiFile"
                    enableDropZone={true}
                    extensionFilters={AppConstants.fileExtensionAllowed}
                    onFileDeleted={onFileDeleted(AppConstants.AttachmentType.OtherCertificates.Type)}
                    onFileDownload={onFileDownload}
                    onFilesAdded={onFilesAdded(AppConstants.AttachmentType.OtherCertificates.Type)}
                    readonly={!props.isFormEditable}
                    attachments={_otherCertificates}
                  ></AttachmentComponent>
                  <FileSizeMSG files={_otherCertificates} />
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserAdditionalData.FormFields.MaritalStatus")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value">
                  <Dropdown
                    disabled={!props.isFormEditable}
                    calloutProps={{ directionalHintFixed: true }}
                    options={_maritalStatusOptions}
                    onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                      _onDLLChange(event, item, AdditionalFormDLLEnum.MaritalStatus);
                    }}
                                      selectedKey={_userProfileFormData?.MaritalStatus?.toUpperCase()}
                  />
                </StackItem>
              </Stack>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserAdditionalData.FormFields.MaritalCert")}
                  {_userProfileFormData?.MaritalStatus && _userProfileFormData?.MaritalStatus != AppConstants.maritalStatus.Single && (
                    <span className="imp">*</span>
                  )}
                </StackItem>
                <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                  <AttachmentComponent
                    texts={Utility.getAttachmentTexts()}
                    fileMode="MultiFile"
                    enableDropZone={true}
                    extensionFilters={AppConstants.fileExtensionAllowed}
                    onFileDeleted={onFileDeleted(AppConstants.AttachmentType.MaritalDocs.Type)}
                    onFileDownload={onFileDownload}
                    onFilesAdded={onFilesAdded(AppConstants.AttachmentType.MaritalDocs.Type)}
                    readonly={!props.isFormEditable}
                    attachments={_maritalDocs}
                  ></AttachmentComponent>
                  <FileSizeMSG files={_maritalDocs} />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem className="formSubSection">
          <Stack>
            <StackItem className="fields">
              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserAdditionalData.FormFields.AddressSectionName")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.AddressSection.Country")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <Dropdown
                        disabled={!props.isFormEditable}
                        calloutProps={{ directionalHintFixed: true }}
                        options={_addressCountryOptions}
                                              selectedKey={_userProfileFormData?.AddressVerification?.Country?.toUpperCase()}
                        onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                          _onDLLChange(event, item, AdditionalFormDLLEnum.ACountry);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.AddressSection.CEP")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData?.AddressVerification?.CEP?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, AdditionalFormTXTEnum.ACEP);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.BirthplaceSection.State")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData?.AddressVerification?.State?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, AdditionalFormTXTEnum.AState);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.AddressSection.City")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData?.AddressVerification?.City?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, AdditionalFormTXTEnum.ACity);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.AddressSection.District")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData?.AddressVerification?.District?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, AdditionalFormTXTEnum.ADistrict);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.AddressSection.PublicArea")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData?.AddressVerification?.PublicArea?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, AdditionalFormTXTEnum.APublicArea);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.AddressSection.Address")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData?.AddressVerification?.Address?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, AdditionalFormTXTEnum.AAddress);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.AddressSection.Number")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData?.AddressVerification?.Number?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, AdditionalFormTXTEnum.ANumber);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserAdditionalData.FormFields.AddressSection.Complement")}
                      <span className="imp">*</span>
                    </StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData?.AddressVerification?.Complement?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, AdditionalFormTXTEnum.AComplement);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>
              <Stack className="field">
                <StackItem className="label">
                  {t("UserAdditionalData.FormFields.ProofOfAddress")}
                  <span className="imp">*</span>
                </StackItem>
                <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                  <AttachmentComponent
                    texts={Utility.getAttachmentTexts()}
                    fileMode="SingleFile"
                    enableDropZone={true}
                    extensionFilters={AppConstants.fileExtensionAllowed}
                    onFileDeleted={onFileDeleted(AppConstants.AttachmentType.AddressDocs.Type)}
                    onFileDownload={onFileDownload}
                    onFilesAdded={onFilesAdded(AppConstants.AttachmentType.AddressDocs.Type)}
                    readonly={!props.isFormEditable}
                    attachment={_addressDocs.length > 0 ? _addressDocs[0] : null}
                  ></AttachmentComponent>
                  <FileSizeMSG files={_addressDocs} />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </>
  );
};
