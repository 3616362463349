import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Icon,
  IDropdownOption,
  INavLink,
  INavLinkGroup,
  Nav,
  SpinnerType,
  Stack,
  TextField,
} from "@fluentui/react";
import { Label, Spinner, SpinnerSize, StackItem } from "@fluentui/react";
import { AttachmentComponent, IFileInfo } from "ccs-azure";
import { Document2FormTXTEnum, SaveTypeEnum } from "components/Utility/AppEnums";
import { Utility } from "components/Utility/Utility";
import { AppCalendar } from "components/Welcome/Common/AppCalendar";
import { AppTopContext } from "components/Welcome/Common/AppTopContext";
import { FileSizeMSG } from "components/Welcome/Common/FileSizeMSG";
import DataProvider from "dataprovider/DataProvider";
import { IAppFileInfo, INavigationActions, IReturnAction, IUserDoc2Data, IUserFieldsState, IUserProfileData } from "Interfaces/IHRBrazil";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppConstants } from "../../../../Utility/Constants";
import { ActionSection } from "./ActionSection";
export interface IDocuments2FormProps {
  user: IUserFieldsState;
  isFormEditable: boolean;
  previousCall: any;
  nextCall: any;
  submitCall: any;
  userProfileData: IUserProfileData;
  userProfileFormData: IUserDoc2Data;
  navigationAction: INavigationActions;
  saveUserProfile: (sectionKey: string, data: any, updateData: boolean, callFunc: any) => void;
  updateFormMode: (formMode: boolean) => void;
}

export const Documents2Form: React.FunctionComponent<IDocuments2FormProps> = ({ ...props }) => {
  const { t, i18n } = useTranslation(AppConstants.i18NameSpaces);
  const appContext = useContext(AppTopContext);
  var dataProvider = new DataProvider();

  const [_userProfileFormData, _setUserProfileFormData] = useState<IUserDoc2Data>(Utility.GetInitialData(AppConstants.SectionKey.Documents2));
  let [_isFileUpdated, _setIsFileUpdated] = useState<boolean>(false);
  let [_licendeDocs, _setLicendeDocs] = useState<Array<IFileInfo | File>>([]);
  let [_militaryCertificate, _setMilitaryCertificate] = useState<Array<IFileInfo | File>>([]);
  let [_voterDocument, _setVoterDocument] = useState<Array<IFileInfo | File>>([]);
  let [_bankDoc, _setBankDoc] = useState<Array<IFileInfo | File>>([]);
  let [_deletedDocs, _setDeletedDocs] = useState<Array<IFileInfo>>([]);
  let [_bankOptions, _setBankOptions] = useState<IDropdownOption[]>([]);
  let [_acTypeOptions, _setAcTypeOptions] = useState<IDropdownOption[]>([]);

  useEffect(() => {
    var bankOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.Bank);
    _setBankOptions(bankOptions);

    var accTypeOptions = Utility.GetChoiceOptions(i18n.language, props, AppConstants.choices.AccountType);
    _setAcTypeOptions(accTypeOptions);
  }, [appContext.IsLBrazil]);

  useEffect(() => {
    if (props.navigationAction.Guid) {
      if (props.isFormEditable) {
        let isFormUpdated = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.Documents2, props.user, _userProfileFormData);
        if (appContext.IsUserAdmin) {
          if (isFormUpdated) {
            appContext.ShowAppModal(t("Information.AdminPanelAction.Header"), t("Information.AdminPanelAction.MSG"));
          } else {
            props.navigationAction.NavigationFun();
          }
        } else {
          callPropSaveDataCallBackFunction(isFormUpdated, props.navigationAction.NavigationFun);
        }
      } else {
        props.navigationAction.NavigationFun();
      }
    }
  }, [props.navigationAction.Guid]);
  useEffect(() => {
    initializeForm();
  }, [props.userProfileFormData]);

  function initializeForm() {
    let initialData = Utility.GetInitialData(AppConstants.SectionKey.Documents2, props.userProfileFormData) as IUserDoc2Data;
    _setLicendeDocs(Utility.GetFiles(initialData.CNH.LDoc));
    _setMilitaryCertificate(Utility.GetFiles(initialData.Military.CDoc));
    _setBankDoc(Utility.GetFiles(initialData.BankData.BDoc));
    _setUserProfileFormData(initialData);
    _setVoterDocument(Utility.GetFiles(initialData.Voter.VDoc));
    _setDeletedDocs([]);
  }
  function resetForm() {
    /// check for change and reset
    let isFormDataUpdated = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.Documents2, props.user, _userProfileFormData);
    if (isFormDataUpdated) {
      appContext.ShowAppConfirmationModal(
        t("ActionBtn.ExitEditModeConfirmation.Header"),
        t("ActionBtn.ExitEditModeConfirmation.MSG"),
        t("ActionBtn.ExitEditModeConfirmation.OKBtnTxt"),
        t("ActionBtn.ExitEditModeConfirmation.CancelBtnTxt"),
        function () {
          /// call reset data
          initializeForm();
        }
      );
    } else {
      props.updateFormMode(false);
    }
  }
  function callPropSaveDataCallBackFunction(isDataUpdateRequire: boolean, func: any) {
    appContext.ShowAppLoader(true, "");
    if (isDataUpdateRequire) {
      if (!Utility.IsFilesSizeValid([_licendeDocs, _militaryCertificate, _bankDoc, _voterDocument])) {
        appContext.ShowAppModal(t("ModalDialogHeader.ValidationMessage"), t("ValidationMessage.FileSizeLimit"));
        appContext.ShowAppLoader(false, "");
        return;
      }
      dataProvider
        .deleteUserDocs(props.user.UserId, _deletedDocs)
        .then((delResponse: any) => {
          if (delResponse?.hasError) {
            console.log(delResponse);
            if (!Utility.IsUnauthorizeError(delResponse.errorDetail, appContext)) {
              appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.DocumentDeleteError"));
            }
            appContext.ShowAppLoader(false, "");
          } else {
            Promise.all([
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.Doc2LicenseDoc.Display, _licendeDocs),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.Doc2MilitaryCertificate.Display, _militaryCertificate),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.Doc2BankDoc.Display, _bankDoc),
              dataProvider.uploadUserDocs(props.user.UserId, AppConstants.AttachmentType.VoterDocument.Display, _voterDocument),
            ])
              .then((res) => {
                let errorMSG = false;
                if (!res[0]?.hasError) {
                  _userProfileFormData.CNH.LDoc = res[0].data as IAppFileInfo[];
                  if (_userProfileFormData.CNH.LDoc.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.CNH.LDoc = [_userProfileFormData.CNH.LDoc[0]];
                  }
                } else {
                  console.log(res[0]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[1]?.hasError) {
                  _userProfileFormData.Military.CDoc = res[1].data as IAppFileInfo[];
                  if (_userProfileFormData.Military.CDoc.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.Military.CDoc = [_userProfileFormData.Military.CDoc[0]];
                  }
                } else {
                  console.log(res[1]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[2]?.hasError) {
                  _userProfileFormData.BankData.BDoc = res[2].data as IAppFileInfo[];
                  if (_userProfileFormData.BankData.BDoc.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.BankData.BDoc = [_userProfileFormData.BankData.BDoc[0]];
                  }
                } else {
                  console.log(res[2]?.ErrorDetail);
                  errorMSG = true;
                }
                if (!res[3]?.hasError) {
                  _userProfileFormData.Voter.VDoc = res[3].data as IAppFileInfo[];
                  if (_userProfileFormData.Voter.VDoc.length > 0) {
                    /// only single file require in json
                    _userProfileFormData.Voter.VDoc = [_userProfileFormData.Voter.VDoc[0]];
                  }
                } else {
                  console.log(res[3]?.ErrorDetail);
                  errorMSG = true;
                }
                if (errorMSG) {
                  appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.DocumentUploadError"));
                  appContext.ShowAppLoader(false, "");
                } else {
                  props.saveUserProfile(AppConstants.SectionKey.Documents2, _userProfileFormData, isDataUpdateRequire, func);
                }
              })
              .catch((err) => {
                appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.GenericError"));
                console.log(err);
                appContext.ShowAppLoader(false, "");
              });
          }
        })
        .catch((err) => {
          appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.GenericError"));
          console.log(err);
          appContext.ShowAppLoader(false, "");
        });
    } else {
      func();
    }
  }
  function actionCallbackFun(saveType: SaveTypeEnum) {
    let actionProps: IReturnAction = Utility.GetActionProps(saveType, props.previousCall, props.nextCall);
    if (actionProps.saveRequired) {
      actionProps.saveRequired = Utility.IsFormUpdated(_isFileUpdated, AppConstants.SectionKey.Documents2, props.user, _userProfileFormData);
    }
    callPropSaveDataCallBackFunction(actionProps.saveRequired, actionProps.callBack);
  }
  const onFileDeleted =
    (attachmentType: string) =>
    (file: IFileInfo | File): Promise<void> => {
      let attachments = [];
      let deletedAttachments = [];
      let idx = -1;
      switch (attachmentType) {
        case AppConstants.AttachmentType.Doc2BankDoc.Type:
          attachments = [..._bankDoc];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setBankDoc(attachments);
          }

          break;
        case AppConstants.AttachmentType.Doc2LicenseDoc.Type:
          attachments = [..._licendeDocs];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setLicendeDocs(attachments);
          }

          break;
        case AppConstants.AttachmentType.Doc2MilitaryCertificate.Type:
          attachments = [..._militaryCertificate];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setMilitaryCertificate(attachments);
          }
          break;
        case AppConstants.AttachmentType.VoterDocument.Type:
          attachments = [..._voterDocument];
          idx = attachments.indexOf(file);
          if (idx !== -1) {
            attachments.splice(idx, 1);
            _setVoterDocument(attachments);
          }
          break;
        default:
          break;
      }
      if (file instanceof File) {
      } else {
        deletedAttachments = [..._deletedDocs];
        deletedAttachments.push(file);
        _setDeletedDocs(deletedAttachments);
      }
      _setIsFileUpdated(true);
      return Promise.resolve(null);
    };
  const onFileDownload = (file: IFileInfo): Promise<void> => {
    appContext.ShowAppLoader(true, "");
    dataProvider
      .downloadUserDoc(props.user.UserId, file.fileId, file.fileName)
      .then((res) => {
        let r = res;
        appContext.ShowAppLoader(false, "");
      })
      .catch((err) => {
        console.log(err);
        appContext.ShowAppLoader(false, "");
        appContext.ShowAppModal(t("ModalDialogHeader.Error"), t("Error.DocumentError.FileDownloadError"));
      });
    return Promise.resolve(null);
  };
  const onFilesAdded =
    (attachmentType: string) =>
    (files: File[]): Promise<void> => {
      let attachments = [];
      switch (attachmentType) {
        case AppConstants.AttachmentType.Doc2BankDoc.Type:
          attachments = [..._bankDoc];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setBankDoc(attachments);
          break;
        case AppConstants.AttachmentType.Doc2LicenseDoc.Type:
          attachments = [..._licendeDocs];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setLicendeDocs(attachments);
          break;
        case AppConstants.AttachmentType.Doc2MilitaryCertificate.Type:
          attachments = [..._militaryCertificate];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setMilitaryCertificate(attachments);
          break;
        case AppConstants.AttachmentType.VoterDocument.Type:
          attachments = [..._voterDocument];
          files.forEach((file) => {
            attachments.push(file);
          });
          _setVoterDocument(attachments);
          break;
        default:
          break;
      }
      _setIsFileUpdated(true);
      return Promise.resolve();
    };
  function _onChangeText(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string, fieldType: Document2FormTXTEnum) {
    let documentFormData = JSON.parse(JSON.stringify(_userProfileFormData)) as IUserDoc2Data;
    switch (fieldType) {
      case Document2FormTXTEnum.BankANo:
        documentFormData.BankData.AccountNumber = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.BankAType:
            documentFormData.BankData.AccountType = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.BankAgency:
            documentFormData.BankData.BAgency = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.BankDigit:
            documentFormData.BankData.Digit = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.CNHEState:
            documentFormData.CNH.EState = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.CNHIAuthority:
            documentFormData.CNH.IAuthority = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.CNHLCategory:
            documentFormData.CNH.LCategory = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.CNHNo:
            documentFormData.CNH.CNHNumber = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.MilitaryRANo:
            documentFormData.Military.RANumber = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.MilitarySerie:
            documentFormData.Military.Serie = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.MilitryCNo:
            documentFormData.Military.CNumber = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.VRCity:
            documentFormData.Voter.City = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.VRElectoralZone:
            documentFormData.Voter.EZone = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.VRNo:
            documentFormData.Voter.VRNumber = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.VRSection:
            documentFormData.Voter.Section = text?.toUpperCase();
        break;
      case Document2FormTXTEnum.VRState:
            documentFormData.Voter.State = text?.toUpperCase();
        break;
      default:
        break;
    }
    _setUserProfileFormData(documentFormData);
  }

  return (
    <>
      <Stack className="formHeader">{t("UserDocuments2.FormHeader")}</Stack>
      <ActionSection
        resetForm={resetForm}
        {...props}
        isFormEditable={props.isFormEditable}
        previousCallFun={actionCallbackFun}
        nextCallFun={actionCallbackFun}
        submitCallFun={undefined}
      />
      <Stack className="formSection formDocument2" horizontal>
        <StackItem className="formSubSection">
          <Stack>
            <StackItem className="fields">
              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserDocuments2.FormFields.VoterRegistration")}</legend>
                <Stack className="field">
                  <StackItem className="label">
                    {t("UserDocuments2.FormFields.VoterRegistrationSection.VoterDocument")}

                    <span className="imp">*</span>
                  </StackItem>
                  <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                    <AttachmentComponent
                      texts={Utility.getAttachmentTexts()}
                      fileMode="SingleFile"
                      attachment={_voterDocument.length > 0 ? _voterDocument[0] : null}
                      enableDropZone={true}
                      extensionFilters={AppConstants.fileExtensionAllowed}
                      onFileDeleted={onFileDeleted(AppConstants.AttachmentType.VoterDocument.Type)}
                      onFileDownload={onFileDownload}
                      onFilesAdded={onFilesAdded(AppConstants.AttachmentType.VoterDocument.Type)}
                      readonly={!props.isFormEditable}
                    ></AttachmentComponent>
                    <FileSizeMSG files={_voterDocument} />
                  </StackItem>
                </Stack>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.VoterRegistrationSection.VoterRegistrationNo")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.Voter.VRNumber?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document2FormTXTEnum.VRNo);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.VoterRegistrationSection.ElectoralZone")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.Voter.EZone?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document2FormTXTEnum.VRElectoralZone);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.VoterRegistrationSection.Section")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.Voter.Section?.toUpperCase()}
                        onChange={(ev, text) => {
                          _onChangeText(ev, text, Document2FormTXTEnum.VRSection);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.VoterRegistrationSection.EmissionDate")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.Voter.EDate ? new Date(_userProfileFormData.Voter.EDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.Voter.EDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.VoterRegistrationSection.State")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.Voter.State?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.VRState);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.VoterRegistrationSection.City")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.Voter.City?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.VRCity);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>
              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserDocuments2.FormFields.CNH")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.CNHSection.LicenseDoc")}</StackItem>
                    <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                      <AttachmentComponent
                        texts={Utility.getAttachmentTexts()}
                        fileMode="SingleFile"
                        attachment={_licendeDocs.length > 0 ? _licendeDocs[0] : null}
                        enableDropZone={true}
                        extensionFilters={AppConstants.fileExtensionAllowed}
                        onFileDeleted={onFileDeleted(AppConstants.AttachmentType.Doc2LicenseDoc.Type)}
                        onFileDownload={onFileDownload}
                        onFilesAdded={onFilesAdded(AppConstants.AttachmentType.Doc2LicenseDoc.Type)}
                        readonly={!props.isFormEditable}
                      ></AttachmentComponent>
                      <FileSizeMSG files={_licendeDocs} />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.CNHSection.CNHNo")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.CNH.CNHNumber?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.CNHNo);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.CNHSection.LicenseCategory")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                        value={_userProfileFormData.CNH.LCategory?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.CNHLCategory);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.CNHSection.IssuingAuthority")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.CNH.IAuthority?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.CNHIAuthority);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.CNHSection.EmissionState")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.CNH.EState?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.CNHEState);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.CNHSection.EmissionDate")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.CNH.EDate ? new Date(_userProfileFormData.CNH.EDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.CNH.EDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.CNHSection.LicenseValidity")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.CNH.LValidity ? new Date(_userProfileFormData.CNH.LValidity) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.CNH.LValidity = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.CNHSection.DateoffirstLicense")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.CNH.FirstLDate ? new Date(_userProfileFormData.CNH.FirstLDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.CNH.FirstLDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem className="formSubSection">
          <Stack>
            <StackItem className="fields">
              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserDocuments2.FormFields.MilitaryCertificate")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">
                      {t("UserDocuments2.FormFields.MilitarySection.CertificateDoc")}
                      {Utility.IsMCertificateRequire(props.userProfileData?.BasicData?.Gender) && <span className="imp">*</span>}
                    </StackItem>
                    <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                      <AttachmentComponent
                        texts={Utility.getAttachmentTexts()}
                        fileMode="SingleFile"
                        attachment={_militaryCertificate.length > 0 ? _militaryCertificate[0] : null}
                        enableDropZone={true}
                        extensionFilters={AppConstants.fileExtensionAllowed}
                        onFileDeleted={onFileDeleted(AppConstants.AttachmentType.Doc2MilitaryCertificate.Type)}
                        onFileDownload={onFileDownload}
                        onFilesAdded={onFilesAdded(AppConstants.AttachmentType.Doc2MilitaryCertificate.Type)}
                        readonly={!props.isFormEditable}
                      ></AttachmentComponent>
                      <FileSizeMSG files={_militaryCertificate} />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.MilitarySection.CertificateNo")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.Military.CNumber?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.MilitryCNo);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.MilitarySection.Serie")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.Military.Serie?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.MilitarySerie);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.MilitarySection.RANumber")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.Military.RANumber?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.MilitaryRANo);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.MilitarySection.DispensationDate")}</StackItem>
                    <StackItem className="value">
                      <AppCalendar
                        IsEditable={props.isFormEditable}
                        InitialDate={_userProfileFormData.Military.DDate ? new Date(_userProfileFormData.Military.DDate) : null}
                        GetCalendatDate={function (date: Date): void {
                          _userProfileFormData.Military.DDate = date.toUTCString();
                          _setUserProfileFormData(_userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>

              <fieldset className="fieldsetsection">
                <legend className="legentStyle">{t("UserDocuments2.FormFields.BankData")}</legend>
                <Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.BankDataSection.BankDataDoc")}</StackItem>
                    <StackItem className="value" style={{ overflowWrap: "anywhere" }}>
                      <AttachmentComponent
                        texts={Utility.getAttachmentTexts()}
                        fileMode="SingleFile"
                        attachment={_bankDoc.length > 0 ? _bankDoc[0] : null}
                        enableDropZone={true}
                        extensionFilters={AppConstants.fileExtensionAllowed}
                        onFileDeleted={onFileDeleted(AppConstants.AttachmentType.Doc2BankDoc.Type)}
                        onFileDownload={onFileDownload}
                        onFilesAdded={onFilesAdded(AppConstants.AttachmentType.Doc2BankDoc.Type)}
                        readonly={!props.isFormEditable}
                      ></AttachmentComponent>
                      <FileSizeMSG files={_bankDoc} />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.BankDataSection.Bank")}</StackItem>
                    <StackItem className="value">
                      <Dropdown
                        disabled={!props.isFormEditable}
                        calloutProps={{ directionalHintFixed: true }}
                        options={_bankOptions}
                        selectedKey={_userProfileFormData.BankData.Bank?.toUpperCase()}
                        onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                          let userProfileFormData = JSON.parse(JSON.stringify(_userProfileFormData)) as IUserDoc2Data;
                          userProfileFormData.BankData.Bank = (item.key as string)?.toUpperCase();
                          _setUserProfileFormData(userProfileFormData);
                        }}
                      />
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.BankDataSection.BankAgency")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.BankData.BAgency?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.BankAgency);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.BankDataSection.AccountType")}</StackItem>
                    <StackItem className="value">
                      <Dropdown
                        disabled={!props.isFormEditable}
                        calloutProps={{ directionalHintFixed: true }}
                        options={_acTypeOptions}
                                              selectedKey={_userProfileFormData.BankData.AccountType?.toUpperCase()}
                        onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
                          let userProfileFormData = JSON.parse(JSON.stringify(_userProfileFormData)) as IUserDoc2Data;
                          userProfileFormData.BankData.AccountType = (item.key as string)?.toUpperCase();
                          _setUserProfileFormData(userProfileFormData);
                        }}
                      />
                      {
                        // <TextField
                        //     disabled={!props.isFormEditable}
                        //     autoComplete="off"
                        //     value={_userProfileFormData.BankData.AccountType}
                        //     onChange={(ev, text) => {
                        //         _onChangeText(ev, text, Document2FormTXTEnum.BankAType);
                        //     }}
                        // ></TextField>
                      }
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.BankDataSection.AccountNumber")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.BankData.AccountNumber?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.BankANo);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                  <Stack className="field">
                    <StackItem className="label">{t("UserDocuments2.FormFields.BankDataSection.Digit")}</StackItem>
                    <StackItem className="value">
                      <TextField
                        disabled={!props.isFormEditable}
                        autoComplete="off"
                                              value={_userProfileFormData.BankData.Digit?.toUpperCase()}
                        onChange={(ev, text) => {
                            _onChangeText(ev, text, Document2FormTXTEnum.BankDigit);
                        }}
                      ></TextField>
                    </StackItem>
                  </Stack>
                </Stack>
              </fieldset>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </>
  );
};
